import { api, getRejectReport } from 'api/report';
import { getReportSubruleList } from 'api/product/subrule';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { cloneDeep } from 'lodash-es';
import dateUtils from '@/utils/dateutils.js';
import { myBlockMixin } from '@/mixins/blockmixins.js';
import optionData from '@/assets/js/optionData';
import { getPidsList } from 'api/product/rankSearch';
import { getPermissionAllListNew } from 'api/account/permission';
import { mapState, mapActions } from 'vuex';
import qs from 'querystring';
import { filterObject } from 'utils/utils';

export default {
  mixins: [myBlockMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      dataParam: null,
      startToEndDate: '',
      radioOptions: ['payout', 'revenue', 'conversions'],
      listLoading: false,
      total: null,
      advReportList: [],
      sourceManagers: [],
      timezoneOption: optionData.timezoneOption,
      categoryOption: optionData.categoryOption,
      fullWidth: document.documentElement.clientWidth,
      pidList: [],
      options: {
        subRule: [],
        platforms: [
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
      },
      // 筛选参数状态
      filterCheckedStatus: [],
      filterTimeStatus: [],
      checkTimeFiles: '',
      filter: {
        countries: null,
        prods: null,
        sourceIds: null,
        offerIds: null,
        offerName: null,
        fromDate: null,
        toDate: null,
        timezone: '+00:00',
        columns: [],
        sorting: 'insRejectTotal',
        sortType: 'desc',
        carriers: null,
        category: null,
        ruleIds: null,
        mmps: [],
      },
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  mounted() {
    //默认选择
    // const end = new Date();
    const start = new Date();
    // start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(start);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    this.managerList();
    this.getPids();
    this.subRuleList();
    this.getSourceIdsList();
    //获取Affiliate
    this.getAffiliateList();
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    managerList() {
      getPermissionAllListNew().then((res) => {
        console.log(res);
        this.sourceManagers = res.result;
      });
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    onlyAdjustBranch() {
      if (this.filter.mmps.length > 0) {
        const mmps = this.filter.mmps;
        return !(
          (mmps.includes('adjust') || mmps.includes('branch')) &&
          (!mmps.includes('af') || !mmps.includes('unKnow'))
        );
      } else {
        return true;
      }
    },
    // sub rule
    subRuleList() {
      getReportSubruleList().then((res) => {
        this.options.subRule = res.result;
      });
    },
    filterNull(val) {
      return val == null ? 0 : val;
    },
    searchListClick(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      const param = {
        ...this.pageParam,
      };
      if (this.filter.offerIds) {
        param.offerIds = this.filter.offerIds;
      }
      if (this.filter.countries) {
        param.countries = this.filter.countries;
      }
      if (this.filter.prods) {
        param.prods = this.filter.prods;
      }
      if (this.filter.affiliateIds) {
        param.affiliateIds = this.filter.affiliateIds.toString();
      }
      if (this.filter.sourceIds) {
        param.sourceIds = this.filter.sourceIds.toString();
      }
      if (this.filter.pmList) {
        param.pmList = this.filter.pmList.toString();
      }
      if (this.filter.platforms) {
        param.platformList = this.filter.platforms.toString();
      }
      if (this.filter.subRuleIds) {
        param.subRuleIds = this.filter.subRuleIds.toString();
      }
      if (this.filter.pids) {
        param.pids = this.filter.pids.toString();
      }
      if (this.filter.affSubs) {
        param.affSubs = this.filter.affSubs.toString();
      }

      if (this.filter.offerTypes) {
        param.offerTypes = this.filter.offerTypes.toString();
      }
      if (this.filter.appImportants) {
        param.appImportants = this.filter.appImportants.toString();
      }
      if (this.filter.mmps) {
        param.mmps = this.filter.mmps.toString();
      }
      if (this.filter.sorting) {
        param.sorting = this.filter.sorting;
      }
      if (this.filter.sortType) {
        param.sortType = this.filter.sortType;
      }
      this.filterCheckedStatus = this.filterCheckedStatus.filter(
        (item) => !['date', 'hour', 'month'].includes(item)
      );
      if (this.filterCheckedStatus.includes('affiliate_id')) {
        if (!this.filterCheckedStatus.includes('affiliate_name')) {
          this.filterCheckedStatus.push('affiliate_name');
        }
      } else {
        this.filterCheckedStatus = this.filterCheckedStatus.filter(
          (item) => item !== 'affiliate_name'
        );
      }
      if (this.filterCheckedStatus.includes('offer_id')) {
        if (!this.filterCheckedStatus.includes('offer_name')) {
          this.filterCheckedStatus.push('offer_name');
        }
      } else {
        this.filterCheckedStatus = this.filterCheckedStatus.filter((item) => item !== 'offer_name');
      }
      if (this.filterTimeStatus.length > 1) {
        this.filterCheckedStatus.push('date');
      }
      if (this.filterTimeStatus.length === 1) {
        this.filterCheckedStatus.push(this.filterTimeStatus[0]);
      }
      param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();

      if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
        let startTime = this.startToEndDate[0];
        let endTime = this.startToEndDate[1];
        param.startTime = startTime + ' 00:00:00';
        param.endTime = endTime + ' 23:59:59';
      }
      const dataParam = cloneDeep(param);
      if (Array.isArray(dataParam.columns)) {
        dataParam.columns = dataParam.columns.join(',');
      }
      this.dataParam = dataParam;
      this.getRejectListReport();
    },
    getRejectListReport() {
      this.listLoading = true;
      let param = {
        ...this.dataParam,
        ...this.pageParam,
      };
      getRejectReport(param)
        .then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.advReportList = response.result;
            this.total = response.total;
          }
        })
        .catch((e) => {
          this.listLoading = false;
          this.$message.error(e);
        });
    },
    formatDate(date) {
      if (
        this.filterTimeStatus.length === 1 &&
        this.filterTimeStatus[0] === 'month' &&
        date !== 'total'
      ) {
        const [year, month] = date.split('-').slice(0, 2);
        return `${year}-${month}`;
      }
      return date;
    },
    // 导出
    exportExcelFileClick() {
      let param = {
        ...this.dataParam,
        ...this.pageParam,
      };
      if (param) {
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.EXPORT_REJECT_REPORT_LIST}?${strParam}`, '_blank');
      }
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.searchListClick(currentPage);
    },
    offerdetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/offer/detail',
        query: {
          offerId: row.offer_id,
        },
      });
      window.open(href, '_blank');
    },
    sourcedetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/source/detail',
        query: {
          sourceId: row.source_id,
        },
      });
      window.open(href, '_blank');
    }, // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      }
      this.searchListClick(1);
    },
  },
};
